import React, {StrictMode, useState} from 'react';
import axios from 'axios';
import {authSteps} from "./authSteps";

function AuthSuccess(props) {

    if (props.step !== authSteps.STATE_SUCCESS) {
        return null;
    }
    return (
        <div>

            <div class="row">
                <div class="col-md-8">
                    <div class="error alert alert-success">
                         Вы успешно авторизовались!
                    </div>
                </div>
            </div>

        </div>


);
}

export default AuthSuccess;