import React, {useEffect, useState} from 'react';
import {authSteps} from "./authSteps";
import PropTypes from "prop-types";
import Checkbox from "./Checkbox";
import PhoneField from "./PhoneField";

PhoneWaiting.propTypes = {
    rootForm: PropTypes.object,
    phone: PropTypes.string,
};

function PhoneWaiting(props) {
    return (
        <div className={"mb-4 justify-center " }>
            <div className="max-w-xs">
                <p className={"text-center font-medium mb-4 text-textLight dark:text-textDarkLightGray text-lg "
                + (props.isRegister ? "hidden" : "")}>
                    Войдите или зарегистрируйтесь
                </p>
                <div>
                    <div className="form-group relative mb-4">
                        <div>
                            <label className="text-xs font-normal mb-2 text-textLight dark:text-textDarkLightGray"
                                   htmlFor="smsauth-phone">
                                <span className="starrequired text-light-red md:text-hover-red">* </span>
                                Номер телефона
                            </label>
                            <PhoneField rootForm={props.rootForm} phone={props.phone} />
                        </div>
                    </div>

                    <Checkbox />
                </div>
            </div>
        </div>
    );
}

export default PhoneWaiting;