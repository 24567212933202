import React, {StrictMode, useEffect, useState} from 'react';
import axios from 'axios';
import {authSteps} from "./authSteps";

function ProviderType(props) {
    const [clickTelegram, setClickTelegram] = useState(false);
    const [clickSms, setClickSms] = useState(true);

    function onClickSms(e) {
        e.preventDefault()
        // setClickTelegram(false)
        // setClickSms(true)
        props.setType('SMS')

    }
    function onClickTelegram(e) {
        e.preventDefault()
        // setClickTelegram(true)
        // setClickSms(false)
        props.setType('TELEGRAM')

    }

        if (props.step === authSteps.STATE_SUCCESS ) {
            return null;

        }


    return (
        <div className="max-w-xs">
            <p className="text-left font-medium mb-4 text-textLight dark:text-textDarkLightGray text-lg">
                Выберите как хотите получить код:</p>
            <div className="soa-property-container flex justify-between max-w-xs w-full"
                 id="bx_934924412_radio_buttons">
                <div className="w-full pr-1">
                    <button onClick={onClickSms} className={"btn link_menu_catalog get_code_button p-3 rounded-lg " +
                                    "cursor-pointer font-normal  text-lg  mb-2 items-center w-full " +
                        (props.type === 'SMS' ? "text-black bg-white border-2 dark:text-light-red dark:border-white border-light-red ": "text-white bg-light-red ")}>
                        SMS
                    </button>
                    {/*<label className="font-semibold dark:font-normal">*/}
                    {/*    <input className="form-check-input ring-0 focus:ring-0 focus:ring-transparent*/}
                    {/*                                    focus:ring-offset-transparent focus:outline-none mr-2"*/}
                    {/*           id="smsOption"*/}
                    {/*           name="PROVIDER_TYPE"*/}
                    {/*           type="radio"*/}
                    {/*           value="SMS" checked/>*/}
                    {/*    SMS*/}
                    {/*</label>*/}
                </div>

                <div className={"w-full " } >
                    <button onClick={onClickTelegram} className={"btn link_menu_catalog get_code_button p-3 rounded-lg "
                                   + "cursor-pointer font-normal  text-lg mb-2 items-center w-full " +
                        (props.type === 'TELEGRAM' ? "text-black bg-white dark:text-light-red dark:border-white border-2 " +
                            "border-light-red ": "text-white bg-light-red ") + (props.tgEnabled === 'Y' ? 'block' : 'hidden')}>
                        Telegram
                    </button>
                    {/*<label className="font-semibold dark:font-normal">*/}
                    {/*    <input className="form-check-input ring-0 focus:ring-0 focus:ring-transparent*/}
                    {/*                                    focus:ring-offset-transparent focus:outline-none mr-2"*/}
                    {/*           id="tgOption"*/}
                    {/*           name="PROVIDER_TYPE"*/}
                    {/*           type="radio"*/}
                    {/*           value="TELEGRAM"/>*/}
                    {/*    TELEGRAM*/}
                    {/*</label>*/}
                </div>
            </div>
        </div>

    );
}

export default ProviderType;