import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import AuthorizationForm from "./AuthorizationForm";

AuthorizationDialog.propTypes = {
    hash: PropTypes.number,
};

function AuthorizationDialog(props) {

    const [visible, setVisible] = useState(true)

    useEffect(() => {
        if (!visible) {
            setVisible(true)
        }
    }, [props.hash]);

    function onCloseClick(e) {
        e.preventDefault()
        setVisible(false)

        document.querySelector('.overlay-box').classList.toggle('hidden');
    }

    return (
        <div className={"md:rounded-xl dark:bg-darkBox bg-white rounded-0 relative z-50 p-8 m-auto md:max-w-md " +
            "md:w-full w-screen md:h-auto h-screen  " + (visible ? "block" : "hidden")}>
            <div className={"close_login_menu absolute top-1.5 right-1.5 " + (visible && !props.isRegister ? "block" : "hidden")}>
                <a onClick={onCloseClick} className="js--close_header_box" href="">
                    <svg width="40" height="40" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.5"
                              d="M55 30C55 43.807 43.807 55 30 55C16.1929 55 5 43.807 5 30C5 16.1929 16.1929 5 30 5C43.807 5 55 16.1929 55 30Z"
                              className="dark:fill-[#464646] fill-textDarkLightGray"/>
                        <path
                            d="M22.4242 22.4242C23.1564 21.6919 24.3436 21.6919 25.0757 22.4242L30 27.3485L34.9242 22.4242C35.6565 21.692 36.8435 21.692 37.5757 22.4242C38.308 23.1564 38.308 24.3436 37.5757 25.076L32.6517 30L37.5757 34.924C38.308 35.6562 38.308 36.8435 37.5757 37.5757C36.8435 38.308 35.6562 38.308 34.924 37.5757L30 32.6517L25.076 37.5757C24.3436 38.308 23.1564 38.308 22.4242 37.5757C21.692 36.8435 21.692 35.6565 22.4242 34.9242L27.3485 30L22.4242 25.0757C21.6919 24.3436 21.6919 23.1564 22.4242 22.4242Z"
                            className="fill-darkBox dark:fill-white"/>
                    </svg>
                </a>
            </div>
            <AuthorizationForm visible={Date()} isRegister={false} />
        </div>
    );
}

export default AuthorizationDialog;