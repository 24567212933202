import React, {StrictMode, useEffect, useState} from 'react';
import axios from 'axios';
import {authSteps} from "./authSteps";

function Checkbox(props) {


    const [visible, setVisible] = useState(true)

    return (
        <div className="checkbox">
            <label className="flex items-center">
                <input type="checkbox" name="SAVE_SESSION" value="Y"
                       className="p-4 dark:bg-grayButton checked:hover:bg-grayButton border-0 dark:text-white
                       cursor-pointer text-textLight font-normal rounded-full bg-textDark checked:focus:bg-grayButton mr-2"
                       id="bx_934924412_save_session"

                />
                <span
                    className="text-xs dark:font-normal font-medium text-textLight dark:text-textDarkLightGray">
                    Запомнить меня
                </span>
            </label>
        </div>

    );
}

export default Checkbox;